require('./site/bootstrap')

import Parallax from 'parallax-js'
import Masonry from 'masonry-layout'

import Dropdown from 'bootstrap5/js/dist/dropdown';
import Collapse from 'bootstrap5/js/dist/collapse';

// import { Fancybox } from '@fancyapps/ui';

import SwiperCore, { Pagination, Navigation, Lazy } from 'swiper/core'
SwiperCore.use([Pagination, Navigation, Lazy])

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

const siteRootEl = document.documentElement
const siteBodyEl = document.body
const siteAppEl = document.getElementById('app')


/*/
 * Parallax
/*/
	const siteParallax = document.querySelectorAll('.parallax')

	if (siteParallax) {
		siteParallax.forEach(e => {
			new Parallax(e, {
				relativeInput: true
			})
		})
	}


/*/
 * LGPD
/*/
	const siteAgreeLGPD = document.querySelector('[ef-lgpd-toggle]')
	
	const siteVerifyLGPD = _ => {
		const element = document.querySelector('[ef-lgpd]')
		const verify = window.localStorage.getItem('lgpd_message_accepted')
		if (!verify) element.classList.add('is--not-acceped')
	}

	window.addEventListener('load', _ => siteVerifyLGPD())	
	siteAgreeLGPD.addEventListener('click', _ => {
		const element = document.querySelector('[ef-lgpd]')

		window.localStorage.setItem('lgpd_message_accepted', '1')
		element.classList.remove('is--not-acceped')
	})


/*/
* Vídeo
/*/
	const siteVideo = document.querySelectorAll('[ef-video]')

	if (siteVideo) {
		siteVideo.forEach(e => {
			e.addEventListener('click', e => {
				e.preventDefault()
				const container = e.target.parentNode.parentNode
				const video = e.target.parentNode
				const template = video.href.split('.').includes('youtube')

				const templateYoutube = `<iframe src="${video.href}?autoplay=1&mute=1&modestbranding=1&rel=0&showinfo=0" class="mr--i_full" width="560" height="315" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
				const templateVimeo = `<iframe src="${video.href}?autoplay=1&autopause=0&muted=1" class="mr--i_full" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`

				video.classList.add('is--playing')
				setTimeout(_ => {
					container.innerHTML = template ? templateYoutube : templateVimeo	
				}, 500);
			})
		})
	}


/*/
 * Animated scroll
/*/
	const siteScroll = _ => {
		const scrollElements = document.querySelectorAll('.sr')
		const scrollObserver = new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					entry.target.classList.add('is--revealed')
				// } else {
				// 	entry.target.classList.remove('is--revealed')
				}
			})
		}, {
			threshold: 0.2
		})

		scrollElements.forEach(target => scrollObserver.observe(target))
	}

	window.addEventListener('load', function () {
		setTimeout(() => {
			siteScroll()
		}, 100)
	})
	

/*/
 * Counter about
/*/
	const counterScroll = _ => {
		const scrollElements = document.querySelectorAll('.sr--counter')
		const scrollObserver = new IntersectionObserver((entries, observer) => {
			entries.forEach((entry, i) => {
				if (entry.isIntersecting && !entry.target.classList.contains('is--revealed')) {
					entry.target.classList.add('is--revealed')

					let _this = entry.target.querySelector('[ef-counter]')

					let element = _this.querySelector('span:first-child')
					let symbol = _this.dataset.symbol ? _this.dataset.symbol : ''
					let milhar = _this.dataset.milhar == 'off' ? true : false
					let language = siteRootEl.lang

					// console.log(element, symbol, milhar, language)

					let start = 0
					let end = _this.dataset.count
					let step = end / 200

					// console.log(start, end, step)

					setTimeout(_ => {
						let interval = setInterval(() => {
							let counter = start += step

							if (!milhar) {
								element.innerHTML = symbol + parseInt(counter).toLocaleString(language)
							} else {
								element.innerHTML = symbol + parseInt(counter)
							}

							if (start > end) {
								clearInterval(interval);
								if (!milhar) {
									element.innerHTML = symbol + parseInt(end).toLocaleString(language)
								} else {
									element.innerHTML = symbol + parseInt(end)
								}
							}
						}, 10);
					}, 100 * (i + 1))
				}
			})
		}, {
			threshold: 0.5
		})

		scrollElements.forEach(target => scrollObserver.observe(target))
	}

	window.addEventListener('load', function () {
		setTimeout(() => {
			counterScroll()
		}, 100)
	})


/*/
 * Process scrollspy
/*/
	window.onload = _ => {
		const siteProcessItem = document.querySelectorAll('.process--list_item')
		// const processClearActive = _ => document.querySelectorAll('[ef-scrollspy]').forEach(e => e.classList.remove('is--active'))

		const observerProcess = new IntersectionObserver((entries) => {
			for (const entry of entries) {
				if (entry.isIntersecting) {
					document.querySelector('[ef-scrollspy="' + entry.target.dataset.ref +'"]').classList.add('is--active')
					// console.log(entry.target.id)
				} else {
					document.querySelector('[ef-scrollspy="' + entry.target.dataset.ref + '"]').classList.remove('is--active')
				}
			}
		}, {
			rootMargin: '-50% 0px'
		})

		for (let i = 0; i < siteProcessItem.length; i++) {
			observerProcess.observe(siteProcessItem[i])
		}
	}


/*/
 * Element off-screen
/*/
	// if (!!window.IntersectionObserver) {
	// 	const siteHeaderEl = document.querySelector('.header')
	// 	const observerHeader = new IntersectionObserver((entries, observer) => {
	// 		entries.forEach(entry => {
	// 			if (!entry.isIntersecting) {
	// 				siteRootEl.classList.add('is--header_scrolled')
	// 			} else {
	// 				siteRootEl.classList.remove('is--header_scrolled')
	// 			}
	// 		})
	// 	}, {
	// 		threshold: 0
	// 	})

	// 	observerHeader.observe(siteHeaderEl)
	// }


/*/
 * Nav
/*/
	const siteNavToggle = document.querySelectorAll('[ef-nav]')
	
	if (siteNavToggle) {
		siteNavToggle.forEach(el => {
			el.addEventListener('click', e => {
				e.preventDefault()
				siteRootEl.classList.toggle('is--nav')
			})
		})

		document.onkeydown = e => {
			if (e.key === 'Escape') {
				siteRootEl.classList.remove('is--nav')
			}
		}
	}


/*/
 * Anchor
/*/
	const siteAnchor = document.querySelectorAll('[ef-anchor]')

	if (siteAnchor) {
		siteAnchor.forEach(el => {
			const target = el.dataset.target

			el.addEventListener('click', e => {
				e.preventDefault()
				document.querySelector(target).scrollIntoView({
					behavior: 'smooth',
				})
			})
		})
	}


/*/
 * Swiper
/*/
	const swiperBannerCount = document.querySelectorAll('.swiper-slide-banner').length > 1 ? true : false
	
	const swiperBanner = new SwiperCore('[ef-banner]', {
		loop: swiperBannerCount,
		speed: 750,
		slidesPerView: 1,
		autoHeight: false,
		observer: true,
		watchOverflow: true,
		threshold: 5,
		lazy: {
			loadPrevNext: true
		},
		navigation: {
			prevEl: '.swiper-prev-banner',
			nextEl: '.swiper-next-banner',
			lockClass: 'd-none',
			disabledClass: 'is--disabled'
		},
		pagination: {
			el: '.swiper-pager-banner',
			clickable: true,
			lockClass: 'd-none',
			bulletActiveClass: 'is--active',
		}
	})


/*/
 * Alert
/*/
	window.siteAlert = function (params) {
		params = {
			text: params.text ? params.text : '',
			class: params.class ? params.class : 'alert--success',
			seconds: params.seconds ? params.seconds : 6000,
		}

		const alert = document.querySelector('[ef-alert]')

		alert.classList.remove('alert--success')
		alert.classList.remove('alert--error')
		alert.classList.add(params.class)
		alert.innerHTML = params.text

		setTimeout(function () { alert.classList.add('is--visible') }, 750)
		setTimeout(function () { alert.classList.remove('is--visible') }, params.seconds)

		alert.addEventListener('click', function () {
			this.classList.remove('is--visible')
		})
	}


/*/
 * Form validation
/*/
	window.addEventListener('load', _ => {
		'use strict'

		const forms = document.querySelectorAll('.form--validate')
		Array.from(forms).forEach(function (form) {
			form.addEventListener('submit', function (event) {
				const button = this.querySelector('[type=submit]')
				const buttonLabel = form.dataset.button
				const formReturnSuccess = form.dataset.success
				const formReturnError = form.dataset.error

				if (!form.checkValidity()) {
					event.preventDefault()
					event.stopPropagation()

					let firstFieldInvalid = this.querySelector(':invalid')

					firstFieldInvalid.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
						inline: 'center',
					})

					setTimeout(() => {
						firstFieldInvalid.focus()
					}, 750);

				} else {
					event.preventDefault()

					button.disabled = true

					const data = new FormData(this)
					const options = {
						headers: { 'Content-Type': 'multipart/form-data' }
					}

					window.axios.post(this.action, data, options)
					.then(_ => {
						form.reset()
						form.classList.remove('is--validated')

						siteAlert({
							text: formReturnSuccess,
							class: 'alert--success',
							seconds: 12000
						})
					}).catch(err => {
						console.error(err)

						siteAlert({
							text: formReturnError,
							class: 'alert--error',
							seconds: 6000
						})
					}).finally(_ => {
						setTimeout(() => {
							button.disabled = false
						}, 2000)
					})
				}

				form.classList.add('is--validated')
			}, false)
		})
	})


/*/
 * Select placeholder
/*/
	const siteSelects = document.querySelectorAll('.form-select')

	if (siteSelects) {
		siteSelects.forEach(e => {
			e.addEventListener('change', _ => {
				if (e.value !== '') {
					e.classList.add('form-select-selected')
				} else {
					e.classList.remove('form-select-selected')
				}
			})
		})
	}


/*/
 * Textarea auto-resize
/*/
	const siteTextarea = document.querySelector('textarea')

	if (siteTextarea) {
		siteTextarea.style.overflow = 'hidden'
		siteTextarea.style.height = _ => siteTextarea.scrollHeight

		siteTextarea.addEventListener('input', e => {
			siteTextarea.style.height = 'calc((var(--fs-small) * 2) + (1rem * var(--lh-content)))'
			siteTextarea.style.height = `${e.target.scrollHeight}px`
		}, false)

		window.addEventListener('resize', _ => {
			setTimeout(() => {
				siteTextarea.style.height = 'auto'
				siteTextarea.style.height = `${siteTextarea.scrollHeight}px`
			}, 100);
		})
	}


/*/
* Dropzone
/*/
	const siteDropfile = document.querySelector('.form-file')
	const siteDragEnter = _ => siteDropfile.classList.add('is--drag')
	const siteDragLeave = _ => siteDropfile.classList.remove('is--drag')

	if (siteDropfile) {
		siteDropfile.addEventListener('dragenter', _ => siteDragEnter())
		siteDropfile.addEventListener('dragleave', _ => siteDragLeave())
		siteDropfile.addEventListener('drop', _ => siteDragLeave())

		siteDropfile.querySelector('input').addEventListener('change', _ => {
			const self = _.target
			const label = self.parentNode.querySelector('.form-file-label')

			if (self.files.length === 1) {
				label.innerText = self.files[0].name
				siteDropfile.classList.add('has--file')
			} else {
				siteDropfile.classList.remove('has--file')
				label.innerText = label.dataset.placeholder
			}

		})
	}


/*/
* Collapse
/*/
	const siteCollapse = document.querySelectorAll('[ef-collapse]')

	if (siteCollapse) {
		siteCollapse.forEach((el, i) => {
			el.addEventListener('click', e => {
				e.preventDefault()

				const collapseCurrent = el.parentElement.parentElement.querySelector('.is--active')
				const collapseSection = document.querySelector('.work--values')
				const prevActiveToggle = collapseSection.querySelector('.is--active')
				const prevActiveCollapse = collapseSection.parentElement.querySelector('.show')

				if (prevActiveToggle && prevActiveCollapse && !collapseCurrent) {
					prevActiveToggle.click()
					prevActiveToggle.classList.remove('is--active')
				}

				el.classList.toggle('is--active')

				let element = el.parentElement.nextElementSibling
				new Collapse(element, { toggle: true })
			})
		})
	}


/*/
 * Masonry
/*/
	const hasGrid = document.querySelector('[ef-masonry]')

	if (hasGrid) {
		const msnry = new Masonry('[ef-masonry]', {
			itemSelector: '.work--grid_item',
			gutter: 0,
			percentPosition: true,
			horizontalOrder: true
		})

		hasGrid.querySelectorAll('img').forEach(e => {
			e.addEventListener('load', function () {
				setTimeout(() => {
					msnry.layout()
				}, 100);
			})
		})
	}


/*/
 * Blá blá blá
/*/
	function doubleTrouble() {
		if (event.key === 'Control') {
			siteCtrlClicks++
			if (siteCtrlClicks >= 2) {
				confirm(' \n 👨🏼‍🎨 Front-end developer :: https://rcrdk.dev \n 💻 Sou Digital :: https://sou.digital \n 🎨 Firmorama :: https://www.firmorama.com/')
			}
			setTimeout(() => (siteCtrlClicks = 0), 200)
		}
	}

	let siteCtrlClicks = 0
	document.addEventListener('keyup', doubleTrouble, true)